import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styles from './referral-modal.module.scss';
import ProfileBadge from '../../../../common/components/profile-badge/profile-badge.component';
import { WelcomeModal } from '../../../../assets/images';
import { QuickStarterBadge } from '../../../../assets/svg';
import { RootState } from '../../../../store/create-store';

type ReferralModalProps = {
  referredBy: any;
  onClose: any;
  getReferrerUser: any;
};

const ReferralModal: React.FunctionComponent<ReferralModalProps> = props => {
  const { referredBy, onClose, getReferrerUser } = props;
  const commonState = useSelector((state: RootState) => state.common);
  const { user } = commonState;
  const [open, setOpen] = React.useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const startTimerHandler = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      getReferrerUser(undefined, true);
    } catch (err) {
      console.log({ err });
    }
  };

  const getCountDown = () => {
    if (referredBy?.contactDetail) {
      if (referredBy.contactDetail.visitStartTime) {
        const startTime = moment();
        const endTime = moment(referredBy.contactDetail.visitStartTime).add(
          30,
          'minutes',
        );
        const remainingTime = endTime.diff(startTime, 'seconds');
        return remainingTime;
      }
      return 1800;
    }
    return 0;
  };

  useEffect(() => {
    if (getCountDown() > 0) {
      setIsImageLoaded(true);
    }
  }, []);
  const renderer = ({ minutes, seconds }: any) => {
    return (
      <div className={styles.timeRenderer}>
        <div className={styles.timeSpan}>
          <p>{minutes}</p>
          <span>Min</span>
        </div>
        <div className={styles.timeSpan} style={{ marginLeft: 10 }}>
          <p>{seconds}</p>
          <span>Sec</span>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (
          ((reason === 'backdropClick' || reason === 'escapeKeyDown') &&
            referredBy?.contactDetail?.visitStartTime &&
            (!referredBy?.contactDetail ||
              getCountDown() <= 0 ||
              user?.isOnboardingCompleted)) ||
          !referredBy?.contactDetail
        ) {
          handleClose();
        }
      }}
      PaperProps={{
        className: styles.referralModal,
      }}
    >
      <div className={styles.modalContainer}>
        {isImageLoaded && (
          <div
            className={styles.referrerBadge}
            style={{ textAlign: 'center', margin: 10 }}
          >
            <div style={{ padding: '0 0rem' }}>
              <span className={styles.referredByName}>
                Welcome to QuickCam{' '}
              </span>
              {(referredBy?.contactDetail?.firstName ||
                referredBy?.contactDetail?.lastName) && (
                <div className={styles.contactName}>
                  {referredBy?.contactDetail?.firstName}{' '}
                  {referredBy?.contactDetail?.lastName}
                </div>
              )}
              <ProfileBadge
                user={referredBy}
                showBadges
                from="referral"
                badgeSize="xSmall"
                badgeClass={styles.referralBadgeWrapper}
                additionalStyle={styles.refProfilePic}
                handleClose={handleClose}
              />
            </div>
            {/* <div className={styles.continueBtn}>
            <button onClick={() => handleClose()} type="button">
              Continue
            </button>
          </div> */}
          </div>
        )}
        {(!user && referredBy?.contactDetail) ||
        (!user && getCountDown() > 0) ||
        (!user && referredBy?.contactDetail?.visitStartTime) ? (
          <>
            <div className={styles.timerContainer}>
              <Countdown
                date={Date.now() + getCountDown() * 1000}
                renderer={renderer}
                autoStart={referredBy?.contactDetail?.visitStartTime}
              />

              <p className={styles.timeText}>
                Sign Up for Free Within 30 Minutes to Earn the QuickStarter
                Badge!
              </p>
              <img
                className={styles.quickStarterBadge}
                src={QuickStarterBadge}
                alt="quickstarterbadge"
              />
            </div>

            {!referredBy?.contactDetail?.visitStartTime ? (
              <div className={styles.btn}>
                <button
                  className={styles.startNow}
                  type="button"
                  onClick={e => startTimerHandler(e)}
                >
                  Start Now
                </button>
              </div>
            ) : (
              <div className={styles.btn}>
                <button
                  className={styles.startNow}
                  type="button"
                  onClick={() => handleClose()}
                >
                  Sign Up Now
                </button>
              </div>
            )}
          </>
        ) : (
          <div className={styles.modalImageContainer}>
            {/* <img
              className={styles.modalImage}
              onLoad={() => setIsImageLoaded(true)}
              src={WelcomeModal}
              alt="WelcomeModal"
            /> */}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ReferralModal;
