import React from 'react';
import { Grid } from '@mui/material';
import styles from './main-features.module.scss';
import camera from '../../../../assets/images/CameraFeature.png';

import social from '../../../../assets/images/SocialFeature.png';

const MainFeatures: React.FC = () => {
  return (
    <section className={styles.container} id="features">
      <h2>Main Features</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className={styles.boxItem}>
            <img className={styles.boxImage} src={camera} alt="Feature" />
            <div>
              <h4>Camera</h4>
              <ul>
                <li>Fast & Slow Motion During Live Recording</li>
                <li>Zoom In & Out During Live Recording</li>
                <li>All with 1-Touch</li>
                <li>Built-In Video Editor</li>
                <li>Pic2Art Photo Filters (iPhone only)</li>
                <li>Create Unique and Fun Videos.</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.boxItem}>
            <img className={styles.boxImage} src={social} alt="Feature" />
            <div>
              <h4>Social Media</h4>
              <ul>
                <li>
                  Share To Social Networks – TikTok, Instagram, Snapchat and
                  More
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.boxItem}>
            <img className={styles.boxImage} src={social} alt="Feature" />
            <div>
              <h4>Make Money</h4>
              <ul>
                <li>
                  Your Unique Referral Link – Share QuickCam with Your Friends,
                  Family and Followers.
                </li>
                <li>
                  Revenue Share – Make Money When They Sign Up and Subscribe!
                </li>
                <li>Referral System - Earn from 2 Levels of Referrals</li>
                <li>
                  QuickStart Bonus - Earn 50% from Personal Referrals First 3
                  Months
                </li>
                <li>
                  Monthly Passive Residual Income
                  <ul>
                    <li>Earn 25% on 1st Level on 4th month and thereafte</li>
                    <li>
                      Earn 25% on 2nd Level on the 4th month and thereafter
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* <p>Main feature description goes here</p> */}
      {/* <Grid container spacing={2} marginTop={1}>
        <Grid item md={6}>
          <b>Camera</b>
          <ul>
            <li>Fast & Slow Motion During Live Recording</li>
            <li>Zoom In & Out During Live Recording </li>
            <li>All with 1-Touch</li>
            <li>Built-In Video Editor </li>
            <li>Pic2Art Photo Filters (iPhone only)</li>
            <li>Create Unique and Fun Videos.</li>
          </ul>
        </Grid>
      </Grid> */}
      {/* <b>Social Media</b>
      <ul>
        <li>
          Share To Social Networks – TikTok, Instagram, Snapchat and More.
        </li>
        <li>
          <span>Gamification - Earn Badges and Gain Followers in QuickCam</span>
        </li>
      </ul>

      <b>Make Money</b>
      <ul>
        <li>
          Invite Wizard – Share QuickCam with Your Friends, Family and
          Followers.
        </li>
        <li>
          <span>
            Revenue Share – Make Money when They Sign Up and Subscribe!
          </span>
        </li>
        <li>
          <span>
            Compensation Plan - Earn from 8 Tiers and 5 Generations of Infinity
            Bonus
          </span>
        </li>
        <li>
          <span>
            QuickStart Bonus - Earn 50% from Personal Referrals First 3 Months
          </span>
        </li>
      </ul> */}
    </section>
  );
};

export default MainFeatures;
