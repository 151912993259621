/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import styles from './income-opportunity.module.scss';
import RefMoney from '../../../../assets/images/MakeMoneyRef.png';
import PersonalRef from '../../../../assets/images/MoneyPersonalRef.png';
import SecondLevel from '../../../../assets/images/secondLevelRef.png';
import SharingCaring from '../../../../assets/images/SharingCaring.png';
// import styles from '../login.module.scss';
// import { IncomeOpportunitiesBack, IOMobile } from '../../../../assets/svg';
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';

const IncomeOpportunities = () => {
  const { width } = useWindowDimensions();
  return (
    // <section
    //   className={styles.incomOpportunitiesWrapper}
    //   id="incomeOpportunities"
    // >
    //   <h2>Make Money Sharing QuickCam</h2>
    //   {width > 767 ? (
    //     <img src={IncomeOpportunitiesBack} alt="IncomeOpportunities" />
    //   ) : (
    //     <img src={IOMobile} alt="IncomeOpportunities" />
    //   )}
    // </section>

    <section className={styles.container}>
      <h2>Money Making Sharing QuickCam</h2>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <div className={styles.box}>
            <div>
              <div className={styles.FirstBox}>
                <img src={RefMoney} alt="" />
              </div>
            </div>
            <div>
              <p className={styles.boxTitle}>Referral System</p>
              Earn Monthly Passive Residual Income
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.box}>
            <div>
              <div className={styles.FirstBox}>
                <img src={PersonalRef} alt="" />
              </div>
            </div>
            <div>
              <p className={styles.boxTitle}>Personal Referrals</p>
              Earn 50% first 3 months, Earn 25% thereafter
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.box}>
            <div>
              <div className={styles.FirstBox}>
                <img src={SecondLevel} alt="" />
              </div>
            </div>
            <div>
              <p className={styles.boxTitle}>2nd Level Referrals</p>
              Earn 25% in the 4th month and thereafter
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.box}>
            <div>
              <div className={styles.FirstBox}>
                <img src={SharingCaring} alt="" />
              </div>
            </div>
            <div>
              <p className={styles.boxTitle}>Sharing is Caring</p>
              Text, DM and Email your referral link
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default IncomeOpportunities;
