import { Divider } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Countdown from 'react-countdown';
import { mQSBanner1 } from '../../../../assets/images';
import {
  AppstoreBadge,
  AppstoreWhiteBadge,
  PlaystoreBadge,
  QuickCamLogoHeader,
  QuickStarterBadge,
  QSPatent,
  QSRiskFree,
} from '../../../../assets/svg';
import { getProfileImageUrl } from '../../../../common/helpers/helpers';
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';
import styles from './quick-starter-banner.module.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const QuickStarterBanner = (props: { referredBy: any }) => {
  const { referredBy } = props;

  const { width } = useWindowDimensions();

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset === 0) {
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderer = ({ minutes, seconds }: any) => {
    return (
      <div className={styles.timeRenderer}>
        <div className={styles.timeSpan}>
          <p>{minutes}</p>
          <span>Min</span>
        </div>
        <div className={styles.timeSpan} style={{ marginLeft: 10 }}>
          <p>{seconds}</p>
          <span>Sec</span>
        </div>
      </div>
    );
  };

  const mRenderer = ({ minutes, seconds }: any) => {
    return (
      <div className={styles.mTimeRenderer}>
        <div className={styles.mTimeSpan}>
          <p>{minutes}</p>
          <span>Min</span>
        </div>
        <div className={styles.mTimeSpan} style={{ marginLeft: 10 }}>
          <p>{seconds}</p>
          <span>Sec</span>
        </div>
      </div>
    );
  };

  const getCountDown = () => {
    if (referredBy?.contactDetail?.visitStartTime) {
      const startTime = moment();
      const endTime = moment(referredBy?.contactDetail?.visitStartTime).add(
        30,
        'minutes',
      );
      const remainingTime = endTime.diff(startTime, 'seconds');
      return remainingTime;
    }
    return 0;
  };

  return width < 787 ? (
    <section className={styles.mContainer}>
      {/* {getCountDown() > 0 ? (
        <>
          <div className={styles.timer}>
            <Countdown
              date={Date.now() + getCountDown() * 1000}
              renderer={mRenderer}
            />
            <p className={styles.timerText}>
              {referredBy?.giftcardIds?.length
                ? 'Sign up for free within 30 mins to get QuickStarter Badge!'
                : 'Sign up for free within 30 mins to extend your free trial to 7 days!'}
            </p>
            <img
              className={styles.Qc_badge}
              src={QuickStarterBadge}
              alt="img"
            />
          </div>
        </>
      ) : (
        <></>
      )} */}

      {/* <section className={styles.showPlatforms}>
        {getCountDown() > 0 ? (
          <div className={styles.divider} />
        ) : (
          <div className={styles.spacer} />
        )}
        <div className={styles.platforms}>
          <p>AVAILABLE NOW</p>
          <div className={styles.badgeContainer}>
            <div className={styles.badges}>
              <img src={PlaystoreBadge} alt="playstore" />
              <span>Google Play</span>
            </div>

            <div className={styles.badges}>
              <img src={AppstoreBadge} alt="appstore" />
              <span>Apple store</span>
            </div>
          </div>
        </div>
        <div className={styles.spacer} />
      </section> */}
    </section>
  ) : (
    <section className={styles.container}>
      <div className={styles.section}>
        <div className={styles.qs_left_banner}>
          <div className={styles.qs_patent}>
            <img src={QSPatent} alt="qs_patent" />
            <p>PATENTED</p>
          </div>
          <div className={styles.qs_risk_free}>
            <img src={QSRiskFree} alt="qs_risk" />
            <div className={styles.free_trial}>
              <p>3 DAY FREE TRIAL</p>
              <span>* No Credit Card Required</span>
            </div>
          </div>
        </div>
        {getCountDown() > 0 ? (
          <>
            <div className={styles.timer}>
              <Countdown
                date={Date.now() + getCountDown() * 1000}
                renderer={renderer}
              />
              <p className={styles.timerText}>
                {referredBy?.giftcardIds?.length
                  ? 'Sign up for free within 30 mins to get QuickStarter Badge!'
                  : 'Sign up for free within 30 mins to extend your free trial to 7 days!'}
              </p>
              <img
                className={styles.Qc_badge}
                src={QuickStarterBadge}
                alt="img"
              />
            </div>
            <div className={styles.profileContainer}>
              <img
                src={getProfileImageUrl(
                  referredBy?.profileImageURL || QuickCamLogoHeader,
                )}
                alt="img"
                className={styles.profilePic}
              />
              <div className={styles.profileTextContainer}>
                <p className={styles.inviterText}>Your Inviter</p>
                <p className={styles.profileName}>
                  {referredBy?.publicDisplayName}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div />
            <div />
          </>
        )}

        <div className={styles.platforms}>
          <p>AVAILABLE NOW</p>
          <div className={styles.badgeContainer}>
            <div className={styles.badges}>
              <img src={PlaystoreBadge} alt="playstore" />
              <span>Google Play</span>
            </div>

            <div className={styles.badges}>
              <img src={AppstoreWhiteBadge} alt="appstore" />
              <span>Apple Store</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuickStarterBanner;
